<template>

  <div v-if="checkIfMobile() == false" id="fullWindow"
    style="width: 100vw;height: 100vh;transform: translateX(-100%);position: fixed;top: 0;left: 0;">

  </div>

  <RouterView v-if="checkIfMobile() == false && showRouterView" @mousedown="handleMouseClick"
    style="position: fixed;left: 0;top: 0;">
  </RouterView>

  <warningForm v-if="checkIfMobile() == false"></warningForm>
  <loadingForm v-if="checkIfMobile() == false"></loadingForm>
  <coverForm v-if="checkIfMobile() == false"></coverForm>
  <menu3Form v-if="checkIfMobile() == false"></menu3Form>
  <changePasswordForm v-if="checkIfMobile() == false"></changePasswordForm>
  <changeRegionForm v-if="checkIfMobile() == false"></changeRegionForm>
  <tipForm v-if="checkIfMobile() == false"></tipForm>
  <audioPlayer v-if="checkIfMobile() == false" v-show="false"></audioPlayer>
  <inputForm v-if="checkIfMobile() == false"></inputForm>
  <menuForm v-if="checkIfMobile() == false"></menuForm>
  <selectThirdListForm v-if="checkIfMobile() == false"></selectThirdListForm>
  <setQuestionIndexForm v-if="checkIfMobile() == false"></setQuestionIndexForm>
  <biaoLvForm v-if="checkIfMobile() == false"></biaoLvForm>
  <selectDateForm v-if="checkIfMobile() == false"></selectDateForm>
  <imagePreviewForm v-if="checkIfMobile() == false"></imagePreviewForm>
  <sanLiStartForm v-if="checkIfMobile() == false"></sanLiStartForm>
  <rseForm v-if="checkIfMobile() == false"></rseForm>
  <startExamForm v-if="checkIfMobile() == false"></startExamForm>
  <endExamForm v-if="checkIfMobile() == false"></endExamForm>

  <div v-if="checkIfMobile()"
    style="width: 100%;height: 100vh;display: flex;align-items: center;justify-content: center;font-family: 微软雅黑;font-size: 24px;">
    请使用电脑浏览，暂不支持手机。
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount, onBeforeMount } from 'vue'
import warningForm from "./components/warningForm.vue";
import store from './module/store'
import loadingForm from "./components/loadingForm"
import coverForm from "./components/coverForm"
import menu3Form from "./components/menu3Form"
import changePasswordForm from "./components/changePasswordForm"
import changeRegionForm from "./components/changeRegionForm"
import tipForm from "./components/tipForm"
import audioPlayer from './components/audioPlayer.vue'
import inputForm from './components/inputForm.vue';
import menuForm from './components/menuForm.vue';
import selectThirdListForm from './components/selectThirdListForm.vue';
import setQuestionIndexForm from './components/setQuestionIndexForm.vue';
import biaoLvForm from './components/biaoLvForm.vue';
import selectDateForm from './components/selectDateForm.vue';
import imagePreviewForm from './components/imagePreviewForm.vue';
import sanLiStartForm from './components/sanLiStartForm.vue';
import rseForm from './components/rseForm.vue';
import startExamForm from './components/startExamForm.vue';
import endExamForm from './components/endExamForm.vue';
import router from './router';
import images from './assets/images';
import tool from './module/tool';
import axios from 'axios';


const checkIfMobile = () => {
  return false
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // 判断是否为手机浏览器
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return true;
  }
  if (/windows phone/i.test(userAgent)) {
    return true;
  }
  return false;
}

const handleMouseClick = (e) => {
  store.setItem('mouseClickPosition', {
    x: e.clientX,
    y: e.clientY
  })
}

const handleWindowResize = () => {
  store.setItem('windowData', {
    width: document.getElementById('fullWindow').clientWidth,
    height: document.getElementById('fullWindow').clientHeight,
    ppr: document.body.clientWidth / 1200
  })
}

onBeforeMount(async () => {

  tool.framePostMessageToParent({
    op: 'getBrowserType'
  })

  try {
    window.electron.receive('electron', () => {
      tool.localStorage.setItem('isApp', 'true')
      location.reload()
    })

    window.electron.receive('electronAdmin', () => {
      tool.localStorage.setItem('isAppAdmin', 'true')
      location.reload()
    })
  } catch (error) {

  }

  window.onMessage = (e) => {
    if (e == 'cefSharp') {
      tool.localStorage.setItem('isApp', 'true')
      location.reload()
    }
    if (e == 'cefSharpAdmin') {
      tool.localStorage.setItem('isAppAdmin', 'true')
      location.reload()
    }
  }

})

const showRouterView = ref(true)

onMounted(async () => {

  localStorage.removeItem('fakeData')
  handleWindowResize()
  window.addEventListener('resize', handleWindowResize)

  await tool.getFrontendSettings()

  router.beforeEach(() => {
    store.setItem('pageZoom', 1)
  })

  if (tool.localStorage.getItem('isApp') == undefined) {
    tool.handlePostMessageToWebview("getBrowserType")
  }

  window.addEventListener("message", async function (event) {
    try {
      // if (event.data == 'frame') {
      //   if (store.getItem('isFrame') == undefined) {
      //     store.setItem('isFrame', true)
      //     showRouterView.value = false
      //     await tool.wait(0.01)
      //     showRouterView.value = true
      //   }
      // }

      if (event.data == 'electron') {
        tool.localStorage.setItem('isApp', 'true')
        location.reload()
      }
    } catch (error) {

    }
  }, false)



})
</script>

<style scoped></style>