const dataStore = (() => {
    const store = {};
    const listeners = {};

    const notifyListeners = (key) => {
        if (listeners[key]) {
            listeners[key].forEach(callback => callback(store[key]));
        }
    };

    return {
        setItem(key, value) {
            store[key] = value;
            notifyListeners(key);
        },

        getItem(key) {
            return store[key];
        },

        removeItem(key) {
            delete store[key];
            notifyListeners(key);
        },

        onChange(key, callback) {
            if (!listeners[key]) {
                listeners[key] = [];
            }
            listeners[key].push(callback);
        }
    };
})();

export default dataStore;