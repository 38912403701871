<script setup>

</script>

<template>
  <div class="rotate-loading"></div>
</template>

<style scoped>

.rotate-loading {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 6px solid rgba(200, 200, 200, 0.5);
  border-top: 6px solid white;
  animation: rotate-loading 1s linear infinite;
  zoom: 1.5;
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    /* 顺时针旋转360度 */
    transform: rotate(360deg);
  }
}

</style>