<template>
    <div v-if="config.show"
        style="position: fixed;left: 0;top: 0;z-index: 9900;width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center;">
        <div :style="{
            zoom: pageZoom.data,
            // marginTop: `calc(80px * ${pageZoom.data})`
            padding: '10px'
        }" style="width: 700px;height: auto;background-color: #1b77e2;">
            <div style="color: white;font-size: 18px;font-weight: bold;">
                错题学习
            </div>
            <div
                style="width: 100%;height: 300px;background-color: #f5f2ed;margin-top: 8px;padding: 10px;font-size: 22px;font-weight: bold;word-break: break-all;overflow-y: auto;">
                {{ config.index.toString() + '. ' + config.questionData.tigan }}
            </div>
            <div style="width: 100%;height: 124px;background-position: center;background-repeat: no-repeat;background-size: contain;margin-top: 8px;"
                :style="{
                    backgroundImage: `url(${config.questionData.timumeiti})`
                }">

            </div>
            <div
                style="width: 100%;height: auto;background-color: #f5f2ed;display: flex;flex-direction: column;padding: 10px;margin-top: 8px;align-items: center;gap: 4px;">
                <div style="width: 100%;font-size: 22px;font-weight: bold;">
                    <span>正确答案：<span v-for="item in config.questionData.daan.split('-')">{{ item }}</span></span>
                    <span style="margin-left: 16px;">你的答案：<span v-for="item in config.questionData.selectedOptions">{{
                        item }}</span></span>
                </div>
                <YButton padding="15px 20px" @click="config.control(false)" width="min-content" background-color="white" border-radius="3px"
                    style="font-weight: bold;border: 1px solid lightgray;"><span style="font-size: 24px;" >确定</span></YButton>
                <p style="font-size: 16px;color: gray;">页面将在{{ parseInt(config.time / 10) }}秒后自动关闭，返回考试主界面</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import YButton from './yButton.vue';

const pageZoom = reactive({
    data: 1,
    handleChange() {
        store.onChange('pageZoom', () => {
            this.data = store.getItem('pageZoom')
        })
    }
})

const config = ref({
    show: false,
    questionData: undefined,
    index: undefined,
    time: 100,
    async control(op) {
        if (op) {
            pageZoom.data = store.getItem('pageZoom')
            this.show = true
            this.time = 109
            while (1) {
                if (this.time <= -1) {
                    config.value.control(false)
                    break
                } else {
                    await tool.wait(0.1)
                    this.time--
                    if (this.show == false) break
                }
            }
        } else {
            this.show = false
        }
    }
})

onMounted(async () => {
    store.onChange('controlRSEForm', () => {
        let data = store.getItem('controlRSEForm')
        config.value.index = data.index
        config.value.questionData = data.question
        config.value.control(data.op)
    })

    pageZoom.handleChange()
})
</script>

<style scoped></style>