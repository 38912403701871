<template>
    <div v-if="config.show != 0"
        style="position: fixed;left: 0;top:0;z-index: 10000;background-color: rgba(0,0,0,0.1);width: 100vw;height: 100vh;transition: background-color, backdrop-filter .3s cubic-bezier(0.1,0.9,0.2,1);display: flex;align-items: center;justify-content: center;"
        :style="{
            backgroundColor: config.show <= 1 ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.2)',
            backdropFilter: config.show <= 1 ? 'blur(0px)' : 'blur(40px)'
        }">
        <div
            style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;background-size: 0.1;opacity: 0.3;"
            :style="{
                backgroundImage: `url(${images.noise})`
            }">

        </div>
        <div :style="{
            opacity: config.show <= 1 ? '0' : '1',
            scale: config.show <= 1 ? '1.1' : '1',
            zoom: zoom,
        }"
            style="display: inline-block;transition-property: opacity, scale;transition-duration: .3s; transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);width: 388px;height: 520px;">
            <div
                style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: flex-start;position: relative;">
                <!-- <div @click="config.control(false)"
                    style="width: 100%;height: 60px;display: flex;align-items: center;justify-content: center;font-size: 22px;color: white;font-family: 微软雅黑;text-shadow: 0 1px 5px rgba(0,0,0,0.9);position: relative;z-index: 10010;">
                    {{ config.title }}
                </div> -->
                <div @click="config.control(false)" style="width: 100%;height: 100%;position: absolute;left: 0;top: 0;">

                </div>
                <div
                    style="width: 100%;height: 520px;display: flex;align-items: center;justify-content: center;gap: 24px;position: relative;z-index: 10010;">
                    <div v-if="0"
                        style="width: 100%;height: 100%;background-color: #fff;border-radius: 16px;box-shadow: 0 4px 10px rgba(0,0,0,0.1);display: flex;flex-direction: column;overflow: hidden;">
                        <div style="width: 100%;height: 80px;display: flex;align-items: center;justify-content: center;background-color: #27cd83;color: white;font-size: 28px;font-family: almm;font-weight: 700;text-shadow: 0 2px 5px rgba(0,0,0,0.3);"
                            :style="{
                                backgroundColor: `rgb(${1 - config.color},${config.color},0)`
                            }">
                            顺序练习
                        </div>
                        <div style="width: 100%;height: 340px;padding: 25px 40px;">
                            <div style="font-size: 19px;font-weight: bold;position: relative;display: inline-block;">
                                什么是三力测试？
                                <div
                                    style="font-size: 19px;width: 4em;background: linear-gradient(90deg, #3ab680,transparent);height: 0.8em;position: absolute;left: 0;bottom: 0;transform: translateY(40%);display: inline-block">

                                </div>
                            </div>

                            <div style="margin-top: 20px;display: inline-block;font-size: 17px;line-height: 1.5;">
                                “三力”测试包含涉及安全驾驶的<span style="color: #d47d43;">记忆力</span>、<span
                                    style="color: #d47d43;">判断力</span>、<span style="color: #d47d43;">反应力</span>测试。</div>

                            <div
                                style="font-size: 19px;font-weight: bold;position: relative;display: inline-block;margin-top: 20px;">
                                哪些人需要参加三力测试？
                                <div
                                    style="font-size: 19px;width: 4em;background: linear-gradient(90deg, #3ab680,transparent);height: 0.8em;position: absolute;left: 0;bottom: 0;transform: translateY(40%);display: inline-block">

                                </div>
                            </div>

                            <div style="margin-top: 20px;display: inline-block;font-size: 14px;line-height: 1.5;">
                                1、年满<span
                                    style="color: #d47d43;">70周岁以上</span>，申请小型汽车、小型自动挡汽车、残疾人专用小型自动挡载客汽车、轻便摩托车准驾车型驾驶证的；
                            </div>

                            <div style="margin-top: 10px;display: inline-block;font-size: 14px;line-height: 1.5;">
                                2、年龄在<span style="color: #d47d43;">60周岁以上、70周岁以下</span>，申请增加轻型牵引挂车、普通二轮摩托车、普通二轮摩托车准驾车型的。
                            </div>
                        </div>
                        <div
                            style="width: 100%;height: 80px;display: flex;align-items: center;justify-content: center;">
                            <yButton @click="handleClick(1)" border-radius="200px" mask-color="white" color="white"
                                background-color="#5dbf84" font-size="22px" padding="16px 110px">
                                开始练习</yButton>

                        </div>
                    </div>
                    <div
                        style="width: 100%;height: 100%;background-color: #fff;border-radius: 16px;box-shadow: 0 4px 10px rgba(0,0,0,0.1);display: flex;flex-direction: column;overflow: hidden;">
                        <div style="width: 100%;height: 80px;display: flex;align-items: center;justify-content: center;background-color: #5191fe;color: white;font-size: 28px;font-family: almm;font-weight: 700;text-shadow: 0 2px 5px rgba(0,0,0,0.3);"
                            :style="{
                                backgroundColor: `rgb(${255 - config.color},${config.color},0)`
                            }">
                            {{ config.data.type == 'exam' ? '考试' : '练习' }}结束
                        </div>
                        <div style="width: 100%;height: 320px;padding: 25px 40px;">
                            <div
                                style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: flex-start;justify-content: center;gap: 24px;">
                                <div style="width: 100%;font-size: 20px;">
                                    <span style="color: gray;">得分</span>
                                    <span
                                        style="float: right;display: inline-block;width: 220px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;text-align: right">{{
                                            config.data.得分 }}分</span>
                                </div>
                                <div style="width: 100%;font-size: 20px;">
                                    <span style="color: gray;">总分</span>
                                    <span
                                        style="float: right;display: inline-block;width: 220px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;text-align: right">{{
                                            config.data.总分 }}分</span>
                                </div>
                                <div style="width: 100%;font-size: 20px;">
                                    <span style="color: gray;">用时</span>
                                    <span style="float: right;">{{ config.data.用时 }}</span>
                                </div>
                                <div style="width: 100%;font-size: 20px;">
                                    <span style="color: gray;">正确率</span>
                                    <span style="float: right;">{{ config.data.正确率 }}</span>
                                </div>
                                <div style="width: 100%;font-size: 20px;">
                                    <span style="color: gray;">{{ config.data.达标 }}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            style="width: 100%;height: 80px;display: flex;align-items: center;justify-content: center;flex-direction: column;gap: 24px;">
                            <yButton width="308px" @click="router.push('/'); config.control(false)"
                                border-radius="200px" mask-color="white" color="white"
                                :background-color="`rgb(${255 - config.color},${config.color},0)`" font-size="22px"
                                padding="16px 10px">
                                返回首页</yButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import yButton from './yButton.vue';
import images from '@/assets/images';
import router from '@/router';

const config = ref({
    show: 0,
    data: {},
    color: '',
    async control(op) {
        if (op) {
            this.show = 1
            await tool.wait(0.001)
            this.show = 2
        } else {
            this.show = 1
            await tool.wait(0.2)
            this.show = 0
        }
    }
})

const zoom = ref(tool.pageZoom())

onMounted(async () => {
    store.onChange('controlExamEndForm', () => {
        let data = store.getItem('controlExamEndForm')
        config.value.data = data.data
        config.value.color = parseFloat(data.data.得分) / parseFloat(data.data.总分) * 255
        config.value.control(data.op)
    })

    tool.watchResize('fullWindow', () => {
        zoom.value = tool.pageZoom()
    })
})
</script>

<style scoped></style>