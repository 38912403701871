<template>
    <audio v-if="showAudio" :src="src" @play="handlePlay" @pause="handlePause" @ended="handleEnded"
        ref="audioElement"></audio>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'
import store from '../module/store'
import tool from '@/module/tool';

// 通过 ref 引用 audio 元素
const audioElement = ref(null)
const src = ref('')

const showAudio = ref(true)

const canEnd = ref(true)

const config = ref({
    endCallBack: () => { }
})

const readText = reactive({
    text: undefined,
    handleStart() {
        if (this.text == undefined) return

        const voices = speechSynthesis.getVoices()
        let utterance = new SpeechSynthesisUtterance(this.text)
        const xiaoxiaoVoice = voices.find(voice => voice.name.indexOf('Xiaoxiao') != -1)
        // // 11_2 // console.log(voices.filter(it => it.lang === 'zh-CN'))
        utterance.voice = xiaoxiaoVoice
        speechSynthesis.speak(utterance)
        utterance.onend = () => {
            handleEnded()
        }
        utterance.onstart = () => {
            // 11_2 // console.log('现在浏览器朗读文字：', this.text)
            store.setItem('audioStatus', true)
        }
        utterance.onpause = () => {
            store.removeItem('audioStatus')
        }
        utterance = null
    },
    handlePause() {
        speechSynthesis.cancel()
    }
})

onMounted(async () => {
    store.onChange('controlAudio', async () => {
        let data = store.getItem('controlAudio')
        if (data.op == 2) {
            pauseAudio()
            let t = src.value
            showAudio.value = false
            await tool.wait(0.1)
            showAudio.value = true
            src.value = t
            await tool.wait(0.1)
            playAudio()
            return
        }
        if (data.op) {
            pauseAudio()
            showAudio.value = false
            await tool.wait(0.1)
            showAudio.value = true
            src.value = data.src
            canEnd.value = data.canEnd == true
            config.value.endCallBack = data.endCallBack
            readText.text = data.readText
            await tool.wait(0.1)
            playAudio()
            console.log(data)
        }
        if (data.op == false) {
            if (canEnd.value == false) return
            pauseAudio()
            readText.handlePause()
        }
    })
})

// 播放音频
const playAudio = () => {
    if (!src.value) {
        if (readText.text != undefined) {
            readText.handleStart()
        }
    } else {
        if (audioElement.value) {
            // 11 // 11_2 // console.log(src.value)
            // console.log(src.value)
            audioElement.value.play()
        }
    }
}

// 暂停音频
const pauseAudio = () => {
    store.removeItem('audioStatus')
    readText.handlePause()
    if (audioElement.value) {
        audioElement.value.pause()
    }
}

// 停止音频
const stopAudio = () => {
    store.removeItem('audioStatus')
    readText.handlePause()
    if (audioElement.value) {
        audioElement.value.stop()
    }
}

// 处理音频播放
const handlePlay = () => {
    store.setItem('audioStatus', true)
    // 11 // 11_2 // console.log('音频开始播放')
}

// 处理音频暂停
const handlePause = () => {
    store.removeItem('audioStatus')
    // 11 // 11_2 // console.log('音频暂停播放')
}

// 处理音频播放结束
const handleEnded = () => {
    store.removeItem('audioStatus')
    // 11 // 11_2 // console.log('音频播放结束')
    try {
        config.value.endCallBack()
    } catch (error) {

    }
}

</script>

<style scoped></style>