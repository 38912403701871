<template>
  <div v-if="config.show"
    style="width: 100vw;height: 100vh;position: fixed;left: 0;top: 0;z-index: 9000;background-color: rgba(0,0,0,0.3);transition-property: opacity, scale;transition-timing-function: cubic-bezier(0.1,0.9,0.2,1);transition-duration: .3s;display: flex;align-items: center;justify-content: center;"
    :style="{
      opacity: config.show > 1 ? 1 : 0,
      scale: config.show == 2 ? '1' : '1.1',
    }">
    <div style="width: 100vw;height: 100vh;position: absolute;left: 0;top: 0;"
      @click="tool.controlChangePasswordForm(0)">

    </div>
    <!-- 中间窗口 -->
    <div
      style="width: calc(100vw - 40px);height: calc(100vh - 40px);max-width: 500px;max-height: 420px;background-color: white;box-shadow:  0 10px 30px rgba(0,0,0,0.5);border-radius: 8px;position: relative;display: inline-block;overflow: hidden;"
      :style="{
        zoom: tool.pageZoom()
      }">
      <div style="width: 100%;height: 60px;position: relative;padding: 16px;">
        <!-- 关闭按钮 -->
        <div style="display: inline-block;float: right;height: 100%;">
          <div
            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: right;font-family: almm;font-weight: 700;">
            <svg @click="tool.controlChangePasswordForm(0)" style="float: right;" width="20" height="20"
              viewBox="0 0 48 48" fill="currentColor">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M23.886 20.957L37.32 7.522a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415L26.714 23.786 40.149 37.22a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414 0L23.886 26.614 10.45 40.049a1 1 0 01-1.415 0l-1.414-1.414a1 1 0 010-1.414l13.435-13.435L7.622 10.35a1 1 0 010-1.415l1.414-1.414a1 1 0 011.415 0l13.435 13.435z"
                fill="currentColor" />
            </svg>
          </div>
        </div>
        <!-- 标题 -->
        <div style="display: inline-block;float: left;height: 100%;">
          <div
            style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: left;font-family: 微软雅黑;font-size: 18px;">
            修改密码
          </div>
        </div>
      </div>
      <!-- 内容区 -->
      <div style="width: 100%;height: calc(100% - 60px);overflow-y: auto;padding: 16px;">
        <input v-model="config.input.old" placeholder="旧密码" type="password" :style="{
          border: config.error.length ? '1px solid red' : '1px solid #f6f8f7',
          color: config.error.length ? 'red' : '#2e4232'
        }"
          style="outline: none;background-color: #f6f8f7;border-radius: 8px;padding: 16px 16px;font-family: 微软雅黑;font-size: 18px;margin-top: 0px;width: 100%;">
        <input v-model="config.input.new" placeholder="新密码" type="password" :style="{
          border: config.error.length ? '1px solid red' : '1px solid #f6f8f7',
          color: config.error.length ? 'red' : '#2e4232'
        }"
          style="outline: none;background-color: #f6f8f7;border-radius: 8px;padding: 16px 16px;font-family: 微软雅黑;font-size: 18px;margin-top: 24px;width: 100%;">
        <input v-model="config.input.newr" placeholder="重复新密码" type="password" :style="{
          border: config.error.length ? '1px solid red' : '1px solid #f6f8f7',
          color: config.error.length ? 'red' : '#2e4232'
        }"
          style="outline: none;background-color: #f6f8f7;border-radius: 8px;padding: 16px 16px;font-family: 微软雅黑;font-size: 18px;margin-top: 24px;width: 100%;">
        <div v-html="config.error" v-if="config.error.length > 0"
          style="width: 100%;font-size: 14px;color: red;margin-top: 16px;width: 100%;word-break: break-all;font-family: 微软雅黑;">

        </div>
        <div style="width: 100%;display: flex;align-items: center;justify-content: right;margin-top: 24px;">
          <div class="button-blue" @click="config.handleChangePassword">
            确认修改
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from '@/router'
import { ref, reactive, defineProps, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import store from '../module/store'
import tool from '../module/tool'
import md5 from 'md5'

const config = ref({
  show: 0,
  async control(op) {
    if (op) {
      this.show = 1
      await tool.wait(0.001)
      this.show = 2
    } else {
      this.show = 1
      await tool.wait(0.3)
      this.show = 0
    }
  },
  error: '',
  input: {
    old: '',
    new: '',
    newr: ''
  },
  async handleChangePassword() {
    this.error = ''
    if (this.input.old == '') {
      this.error = '请输入旧密码'
      return
    }
    if (this.input.new == '') {
      this.error = '请输入新密码'
      return
    }
    if (this.input.newr == '') {
      this.error = '请再次输入新密码'
      return
    }
    if (this.input.new != this.input.newr) {
      this.error = '两次输入的新密码不一致'
      return
    }
    if (tool.checkPassword(this.input.new).status == 0) {
      this.error = tool.checkPassword(this.input.new).content
      return
    }
    let re = await tool.requestData('/' + store.getItem('userType') + '/change_password', {
      oldPassword: md5(this.input.old),
      newPassword: md5(this.input.newr)
    })
    if (re.status) {
      tool.controlChangePasswordForm(false)
      tool.controlWarningForm(true, '修改成功', async () => {

      }, undefined, false)
    } else {
      this.error = re.content
    }
  }
})

onMounted(async () => {
  store.onChange('controlChangePasswordForm', async () => {
    let data = store.getItem('controlChangePasswordForm')
    if (data.op == true) {
      config.value.input.old = ''
      config.value.input.new = ''
      config.value.input.newr = ''
      config.value.error = ''
    }
    config.value.control(data.op)
  })
})
</script>

<style scoped></style>