import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../main.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login.vue')
  },
  {
    path: '/adminLogin',
    name: 'adminLogin',
    component: () => import('../pages/login.vue')
  },
  {
    path: '/de',
    name: 'de',
    component: () => import('../pages/de.vue')
  },
  {
    path: '/rse',
    name: 'rse',
    component: () => import('../pages/rse.vue')
  },
  {
    path: '/tse',
    name: 'tse',
    component: () => import('../pages/tse.vue')
  },
  {
    path: '/blank',
    name: 'blank',
    component: () => import('../pages/blank.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../pages/admin.vue')
  },
  {
    path: '/coach',
    name: 'coach',
    component: () => import('../pages/coach.vue')
  },
  {
    path: '/uploadExplanatorySpeech',
    name: 'uploadExplanatorySpeech',
    component: () => import('../pages/uploadExplanatorySpeech.vue')
  },
  {
    path: '/autoSkip',
    name: 'autoSkip',
    component: () => import('../pages/autoSkip.vue')
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../pages/blank.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
