export default {
    insert(path, data = undefined, res = undefined) {
        return//永久禁止使用
        let store = undefined
        if (localStorage.getItem('fakeData') == undefined) {
            store = []
        } else {
            try {
                store = JSON.parse(localStorage.getItem('fakeData'))
            } catch (error) {
                store = []
            }
        }
        if (data == undefined) data = {}
        if (res == undefined) res = {}

        data = JSON.stringify(data)
        res = JSON.stringify(res)
        let index = -1
        for (let i = 0; i < store.length; i++) {
            if (store[i].path == path && store[i].data == data) {
                index = i
                break
            }
        }
        if (index == -1) {
            store.push({
                path: path,
                data: data,
                res: res
            })
        } else {
            store[index].res = res
        }
        localStorage.setItem('fakeData', JSON.stringify(store))
    },
    get(path, data) {
        let store = undefined
        try {
            store = JSON.parse(localStorage.getItem('fakeData'))
        } catch (error) {
            store = []
        }
        let currentPath = []
        if (data == undefined) data = {}
        data = JSON.stringify(data)
        for (let i = 0; i < store.length; i++) {
            if (store[i].path == path) {
                currentPath.push(store[i])
            }
        }
        for (let i = 0; i < store.length; i++) {
            if (store[i].data == data) {
                return JSON.parse(store[i].res)
            }
        }
        if (currentPath.length) {
            return JSON.parse(currentPath[0].res)
        }
        return {
            status: 0,
            content: '无假数据'
        }
    }
}