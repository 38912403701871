<template>
    <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode"
        style="border-bottom: 1px solid #ccc;border-radius: 8px 8px 0 0;" />
    <Editor :defaultConfig="editorConfig" :mode="mode" v-model="valueHtml"
        style="height: 400px; overflow-y: hidden;border-radius: 8px;" @onCreated="handleCreated"
        @onChange="handleChange" @onDestroyed="handleDestroyed" @onFocus="handleFocus" @onBlur="handleBlur"
        @customAlert="customAlert" @customPaste="customPaste" />
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css'
import { onBeforeUnmount, ref, shallowRef, onMounted, watch, defineEmits, reactive } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import yButton from '@/components/yButton.vue';
import tool from '@/module/tool';

const emit = defineEmits(['input'])

const props = defineProps({
    value: {
        type: String,
        default: ''
    }
})

const config = reactive({
    edited: false,
    async handleSave() {
        if (config.edited == false) return
        let re = await tool.requestData('/admin/update_about', {
            about: valueHtml.value
        }, 'post')
        if (re.status == 1) {
            config.edited = false
            tool.controlWarningForm(1, '成功')
        } else {
            tool.controlWarningForm(1, re.content)
        }
    },
    async handleSaveAndPreview() {
        if (config.edited == false) return
        let re = await tool.requestData('/admin/update_about', {
            about: valueHtml.value
        }, 'post')
        if (re.status == 1) {
            config.edited = false
            tool.handleGetAndShowAboutUS()
        } else {
            tool.controlWarningForm(1, re.content)
        }
    },
})

// 编辑器实例，必须用 shallowRef，重要！
const editorRef = shallowRef()

// 内容 HTML
const valueHtml = ref('<p>hello</p>')

watch(() => valueHtml.value, () => {
    config.edited = true
    emit('input', valueHtml.value)
})

// 模拟 ajax 异步获取内容
onMounted(async () => {
    await tool.wait(0.01)
    valueHtml.value = props.value
})

const toolbarConfig = {}
const editorConfig = { placeholder: '请输入内容...' }

// 组件销毁时，也及时销毁编辑器，重要！
onBeforeUnmount(() => {
    const editor = editorRef.value
    if (editor == null) return

    editor.destroy()
})

// 编辑器回调函数
const handleCreated = (editor) => {
    // 22 // 11_2 // console.log('created', editor);
    editorRef.value = editor // 记录 editor 实例，重要！
}
const handleChange = (editor) => {
    // 22 // 11_2 // console.log('change:', editor.getHtml());
}
const handleDestroyed = (editor) => {
    // 22 // 11_2 // console.log('destroyed', editor)
}
const handleFocus = (editor) => {
    // 22 // 11_2 // console.log('focus', editor)
}
const handleBlur = (editor) => {
    // 22 // 11_2 // console.log('blur', editor)
}
const customAlert = (info, type) => {
    alert(`【自定义提示】${type} - ${info}`)
}
const customPaste = (editor, event, callback) => {
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedText = clipboardData.getData('Text') // 获取纯文本
    const pastedHtml = clipboardData.getData('text/html') // 获取HTML文本（如果有的话）

    if (pastedHtml) {
        // 如果剪贴板中有HTML内容，插入HTML
        editor.insertHtml(pastedHtml)
    } else {
        // 否则插入纯文本
        editor.insertText(pastedText)
    }

    callback(false)  // 返回 false ，阻止默认粘贴行为
}

const insertText = () => {
    const editor = editorRef.value
    if (editor == null) return
    editor.insertText('hello world')
}

const printHtml = () => {
    const editor = editorRef.value
    if (editor == null) return
}

const disable = () => {
    const editor = editorRef.value
    if (editor == null) return
    editor.disable()
}

</script>
